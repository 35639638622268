<i18n>
  {
    "es": {
      "EMAIL": "Dirección de correo electrónico:",
      "REGISTER": "Registrarme",
      "CONFIRM": "Confirmo que he leído y acepto la",
      "PRIVACY": "política de privacidad.",
      "UNKNOWN_ERROR": 'Upsss, algo ha fallado. Vuelva a intentarlo mas tarde', 
      "FIRSTNAME_REQUIRED": 'El nombre es requerido',
      "EMAIL_REQUIRED": 'El correo electrónico es requerido',
      "EMAIL_NOT_VALID": 'El correo electrónico no es válido',
      "FIRSTNAME_LENGTH": 'El nombre debe tener al menos 3 caracteres',
      "SUBSCRIBE_SUCCESS": '¡Gracias por suscribirte a nuestra newsletter!',
      "TERMS_REQUIRED": "Debes aceptar la Política de Privacidad",
      "SAVING": "Guardando",
      "SAVING_ICON": "Icono de guardando",
    },
    "en": {
      "EMAIL": "Email address:",
      "REGISTER": "Register",
      "CONFIRM": "I confirm that I have read and accept the",
      "PRIVACY": "privacy policy.",
      "UNKNOWN_ERROR": 'Upsss, something went wrong. Please try again later',
      "FIRSTNAME_REQUIRED": 'First name is required',
      "EMAIL_REQUIRED": 'Email is required',
      "EMAIL_NOT_VALID": 'Email is not valid',
      "FIRSTNAME_LENGTH": 'First name must be at least 3 characters',
      "SUBSCRIBE_SUCCESS": 'Thank you for subscribing to our newsletter!',
      "TERMS_REQUIRED": "Debes aceptar la Política de Privacidad",
      "SAVING": "Saving",
      "SAVING_ICON": "Saving icon",
    }
  }
</i18n>

<template>
  <div
    id="newsletter"
    class="w-full [background-position-x:50%] bg-cover grid mx-auto px-4 max-w-1400 lg:px-9 md:h-480"
    :style="`background-image: url(${isMobile && blok.mobileImage?.filename ? OptimizeStoryBlokImage(blok.mobileImage.filename) : OptimizeStoryBlokImage(blok.desktopImage.filename)})`"
  >
    <div
      class="p-8 mx-3 my-8 flex flex-col md:w-600 md:h-fit items-start bg-white"
    >
      <h2 class="font-extrabold text-3xl tracking-wider">
        {{ blok.title }}
      </h2>
      <p class="mt-4 text-lg font-medium tracking-wide">
        {{ blok.subtitle }}
      </p>
      <div class="w-full mt-1">
        <input
          :value="formData.firstname"
          @input="formData.firstname = $event.target.value"
          class="outline-none rounded border-1 border-others-grey3 text-xs md:text-sm font-medium py-4 px-3 mt-4 w-full tracking-wider"
          placeholder="Nombre"
        />
      </div>

      <div
        class="w-full flex flex-col items-start md:grid md:grid-cols-[2fr_0.5fr] md:gap-3"
      >
        <input
          :value="formData.email"
          @input="formData.email = $event.target.value"
          class="outline-none rounded border-1 border-others-grey3 text-xs md:text-sm font-medium py-4 px-3 mt-4 w-full tracking-wider"
          :placeholder="
            blok.inputplaceholder ? blok.inputplaceholder : t('EMAIL')
          "
        />
        <button
          :disabled="!buttonEnabled"
          @click="handleSubscribe"
          class="mt-4 font-extrabold text-sm tracking-wide text-white outline-none rounded px-5 border-none w-full h-[50px] md:h-[53px] flex items-center justify-center"
          :style="`background-color: ${blok.buttoncolor?.color ?? '#0F0F0F'}`"
          :class="{
            'cursor-not-allowed': saving,
            'opacity-50 cursor-not-allowed': !buttonEnabled && !saving,
            'opacity-100': buttonEnabled || saving,
          }"
        >
          <img
            v-if="saving"
            src="@/assets/svg/icons/icons-loading.svg"
            :alt="t('SAVING_ICON')"
            class="animate-spin mr-2 h-5 w-5 text-white"
          />

          <span v-if="saving">{{ t('SAVING') }}</span>
          <span v-else>{{
            blok.buttontext ? blok.buttontext : t('REGISTER')
          }}</span>
        </button>
      </div>
      <div class="flex items-start md:items-center space-x-3 md:space-x-5 mt-4">
        <div
          class="text-others-black hovered hover:text-sanremo-black transition-all duration-300"
        >
          <button
            @click="formData.terms = !formData.terms"
            aria-label="terms"
            type="button"
            class="w-6 h-6 border-1 relative rounded grid place-items-center before:bg-sanremo-black before:top-0 before:left-0 before:w-full before:h-full before:opacity-0 before:rounded before:scale-[1.4] before:absolute hovered-hover:before:opacity-30 transition-all duration-300"
            :class="
              formData.terms
                ? 'bg-sanremo-black border-sanremo-black'
                : 'bg-white  border-others-black'
            "
          >
            <img
              v-if="formData.terms"
              src="@/assets/svg/icons/icon-check-white.svg"
              alt="icono de check"
            />
          </button>
        </div>
        <div class="text-xs md:text-sm leading-6">
          <span>
            {{ t('CONFIRM') }}
            <NuxtLink
              to="https://help.perfumeriassanremo.es/hc/es-es/articles/18549303751570-Pol%C3%ADtica-de-privacidad"
              target="_blank"
              class="underline text-xs md:text-sm"
              >{{ t('PRIVACY') }}</NuxtLink
            >
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useVuelidate } from '@vuelidate/core'
import { required, email, minLength, or, helpers } from '@vuelidate/validators'
import { OptimizeStoryBlokImage } from '@/utils/helpers'

const props = defineProps({
  blok: {
    type: Object,
    required: true,
  },
})

const { isMobile } = useDevice()
const { addMessage } = useSnackBar()
const { t } = useI18n()
const saving = ref(false)

const formData = ref({
  email: '',
  firstname: '',
  terms: false,
})

const rules = computed(() => {
  return {
    firstname: {
      required: helpers.withMessage(t('FIRSTNAME_REQUIRED'), required),
      minLength: helpers.withMessage(t('FIRSTNAME_LENGTH'), minLength(3)),
    },
    email: {
      required: helpers.withMessage(t('EMAIL_REQUIRED'), required),
      email: helpers.withMessage(t('EMAIL_NOT_VALID'), email),
    },
    terms: {
      required: helpers.withMessage(
        t('TERMS_REQUIRED'),
        (value) => value === true
      ),
    },
  }
})

const v$ = useVuelidate(rules, formData)

const buttonEnabled = computed(() => {
  if (!v$.value) return false
  v$.value.$validate()
  return v$.value.$error || v$.value.$invalid ? false : true
})

const handleSubscribe = async () => {
  v$.value.$validate()
  if (v$.value.$error) {
    const firstError = v$.value.$errors[0]
    addMessage({
      type: 'error',
      result: 'ERROR',
      text: firstError.$message,
    })
    return
  }

  saving.value = true

  try {
    await $fetch('/api/public/newsletter/subscribe', {
      method: 'POST',
      body: {
        email: formData.value.email,
        firstname: formData.value.firstname,
      },
    })
    addMessage({
      type: 'success',
      result: 'SUCCESS',
      text: t('SUBSCRIBE_SUCCESS'),
    })
  } catch (error) {
    addMessage({
      type: 'error',
      result: 'ERROR',
      text: t('UNKNOWN_ERROR'),
    })
  }

  formData.value.email = ''
  formData.value.firstname = ''
  formData.value.terms = false
  saving.value = false
}
</script>
